

import axios from 'axios';

// Create axios instance with Authorization header
const api = axios.create({
  baseURL: 'https://api.pbp-edms.com', 
  headers: {
    'Content-Type': 'application/json',
  }

  // baseURL: 'http://localhost:8001',
});

// Add a request interceptor to include token
api.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('authToken');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
    //console.log('Authorization header set:', config.headers['Authorization']);
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});


export default api;
