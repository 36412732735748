const Logo = () => {
  return (
    <img
      src="/images/PBP_Construction_Co_Ltd.png"
      alt="PBP Construction"
      style={{
        width: "auto",
        height: "72px",
      }}
    />
  );
};

export default Logo;



/* 
import NavbarLogo from "./public/images/PBP_Construction_Co_Ltd.png";

const Logo = () => {
  return (
   

 <img
      src={NavbarLogo}
      alt="PBP-Logo"
      style={{
      
        width: "auto",
        height: "72px",
      
      }}
    /> 
  );
};

export default Logo; */

/* 
const Logo = () => {
  return (
    <img
      src="/images/PBP_Construction_Co_Ltd.png"
      alt="PBP-Logo"
      style={{
        width: 'auto',
        height: '72px',
      }}
    />
  );
};

export default Logo;
 */
