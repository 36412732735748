import { lazy } from "react";
import AdminRoute from "./AdminRoute"; // import the AdminRoute component

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";

// dashboard routing
const Dashboard = Loadable(lazy(() => import("views/dashboard")));

// utilities routing
const UtilsSupport = Loadable(lazy(() => import("views/utilities/Support")));
const UtilsTrash = Loadable(lazy(() => import("views/utilities/Trash")));
const UtilsInputdocument = Loadable(
  lazy(() => import("views/utilities/Inputdocument"))
);
const UtilsSenddocument = Loadable(
  lazy(() => import("views/utilities/Senddocument"))
);
const UtilsInputregister = Loadable(
  lazy(() => import("views/utilities/Inputregister"))
);
const UtilsSendregister = Loadable(
  lazy(() => import("views/utilities/Sendregister"))
);
const UtilsReport = Loadable(lazy(() => import("views/utilities/Report")));
const UtilsSearchdocument = Loadable(
  lazy(() => import("views/utilities/Searchdocument"))
);
const UtilsReceiveddocumentdetails = Loadable(
  lazy(() => import("views/utilities/Receiveddocumentdetails"))
);
const UtilsAdmin = Loadable(lazy(() => import("views/utilities/Admin")));

const UtilsPageError = Loadable(
  lazy(() => import("views/utilities/PageError"))
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <Dashboard />,
    },
    {
      path: "dashboard",
      children: [
        {
          path: "/dashboard",
          element: <Dashboard />,
        },
      ],
    },
    {
      path: "receiveddocumentdetails",
      element: <UtilsReceiveddocumentdetails />,
      children: [
        {
          path: ":id",
          element: <UtilsReceiveddocumentdetails />,
        },
      ],
    },
    {
      path: "support",
      element: <UtilsSupport />,
    },
    {
      path: "trash",
      element: <UtilsTrash />,
    },
    {
      path: "inputregister",
      element: <UtilsInputregister />,
    },
    {
      path: "sendregister",
      element: <UtilsSendregister />,
    },
    {
      path: "searchdocument",
      element: <UtilsSearchdocument />,
    },
    {
      path: "inputdocument",
      element: <UtilsInputdocument />,
    },
    {
      path: "senddocument",
      element: <UtilsSenddocument />,
    },
    {
      path: "report",
      element: <UtilsReport />,
    },
    {
      path: "admin",
      element: (
        <AdminRoute>
          <UtilsAdmin />
        </AdminRoute>
      ),
    },
    //  {
    //   path: "admin",
    //   element: <UtilsAdmin />,
    // },
    {
      path: "*",
      element: <UtilsPageError />,
    },
  ],
};

export default MainRoutes;

/* import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const Dashboard = Loadable(lazy(() => import('views/dashboard')));

// utilities routing
const UtilsSupport = Loadable(lazy(() => import('views/utilities/Support')));
const UtilsTrash = Loadable(lazy(() => import('views/utilities/Trash')));
const UtilsInputdocument = Loadable(lazy(() => import('views/utilities/Inputdocument')));
const UtilsSenddocument = Loadable(lazy(() => import('views/utilities/Senddocument')));
const UtilsInputregister = Loadable(lazy(() => import('views/utilities/Inputregister')));
const UtilsSendregister = Loadable(lazy(() => import('views/utilities/Sendregister')));
const UtilsReport = Loadable(lazy(() => import('views/utilities/Report')));
const UtilsSearchdocument = Loadable(lazy(() => import('views/utilities/Searchdocument')));
const UtilsReceiveddocumentdetails = Loadable(lazy(() => import('views/utilities/Receiveddocumentdetails')));
const UtilsAdmin = Loadable(lazy(() => import('views/utilities/Admin')));

//const UtilsPageError = Loadable(lazy(() => import('views/utilities/PageError')));


// ==============================|| MAIN ROUTING ||============================== //

 const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <Dashboard />
    },
    {
      path: 'dashboard',
      children: [
        {
          path: '/dashboard',
          element: <Dashboard />
        }
      ]
    },
    {
      path: '/',
      children: [
        {
          path: 'receiveddocumentdetails',
          element: <UtilsReceiveddocumentdetails />
        }
      ]
    },
    {
      path: '/',
      children: [
        {
          // Assuming you want to capture a parameter like 'id'
          path: 'receiveddocumentdetails/:id',
          element: <UtilsReceiveddocumentdetails />
        }
      ]
    },
    {
      path: '/',
      children: [
        {
          path: 'support',
          element: <UtilsSupport />
        }
      ]
    },
    {
      path: '/',
      children: [
        {
          path: 'trash',
          element: <UtilsTrash />
        }
      ]
    },
    {
      path: '/',
      children: [
        {
          path: 'inputregister',
          element: <UtilsInputregister />
        }
      ]
    },
    {
      path: '/',
      children: [
        {
          path: 'sendregister',
          element: <UtilsSendregister />
        }
      ]
    },
    {
      path: '/',
      children: [
        {
          path: 'searchdocument',
          element: <UtilsSearchdocument />
        }
      ]
    },
    {
      path: '/',
      children: [
        {
          path: 'inputdocument',
          element: <UtilsInputdocument />
        }
      ]
    },
    {
      path: '/',
      children: [
        {
          path: 'senddocument',
          element: <UtilsSenddocument />
        }
      ]
    },
    {
      path: '/',
      children: [
        {
          path: 'report',
          element: <UtilsReport />
        }
      ]
    },
    
    
    {
      path: '/',
      children: [
        {
          path: 'admin',
          element: <UtilsAdmin />
        }
      ]
    }
   
  ]
}; 


export default MainRoutes;
 */
